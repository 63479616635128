<template>
    <a v-show="visible" class="scroll-top" href="#top">
        <i class="fa fa-angle-double-up"></i>
    </a>
</template>

<script>
export default {
    name: 'Scrolltop',
    data () {
        return {
            visible: false
        };
    },
    methods: {
        scrollListener: function (e) {
            this.visible = window.scrollY > 150;
        }
    },
    mounted: function () {
        window.addEventListener('scroll', this.scrollListener);
    },
    beforeDestroy: function () {
        window.removeEventListener('scroll', this.scrollListener);
    }
};

</script>

<style scoped lang="scss">
.scroll-top {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    width: 35px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    background-color: #2f2f2f6e;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-shadow: 0 0 0 1px #fff inset, 0 0 0 3px #2f2f2f6e;
    -moz-box-shadow: 0 0 0 1px #fff inset, 0 0 0 3px #2f2f2f6e;
    box-shadow: 0 0 0 1px #fff inset, 0 0 0 3px #2f2f2f6e;
    display: flex;
    align-items: center;
    justify-content: center;

    & > i {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
        display: block;
        font-size: 1.7rem;
        color: white;
    }

    &:hover {
        -webkit-box-shadow: 0 0 0 1px var(--color-primary) inset, 0 0 0 3px #2f2f2f6e;
        -moz-box-shadow: 0 0 0 1px var(--color-primary) inset, 0 0 0 3px #2f2f2f6e;
        box-shadow: 0 0 0 1px var(--color-primary) inset, 0 0 0 3px #2f2f2f6e;

        & > i {
            color: var(--color-primary);
        }
    }
}

</style>
